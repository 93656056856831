import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./pc/index";
import mobileRoutes from "./mobile/index";
import { AESSecretKey } from "@/constant";
import { AES_ECB_ENCRYPT } from "@/utils/AESUtils";
import { addVisits } from "@/service/system";

Vue.use(VueRouter);

const router = new VueRouter({
  routes:
    /Mobi|Android|iPhone/i.test(navigator.userAgent) || window.innerWidth <= 750
      ? mobileRoutes
      : routes,
});

// [解决 vue-router跳转相同路径报错 ]
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

//用户访问页面增加访问统计量
router.beforeEach((to, from, next) => {
  const timestamp = Date.parse(new Date());
  const AESData = AES_ECB_ENCRYPT(
    JSON.stringify({ recordType: "recordType" }),
    AESSecretKey
  );
  const query = {
    req_time: timestamp,
    data: AESData,
  };
  next();
  addVisits(query);
});

export default router;
