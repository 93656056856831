export default [
  {
    path: "/introduction",
    component: () =>
      import(
        /* webpackChunkName: "renyiOverview" */ "@/views/mobile/renyiOverview/introduction.vue"
      ),
    meta: {
      title: "人艺概览",
    },
  },
];
