<template>
  <div id="app" :style="{ filter: theme == 'black' ? 'grayscale(1)' : 'none' }">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    theme() {
      return this.$store.state.systemTheme;
    }
  },
  mounted() {
    this.$store.dispatch('getSystem');
  },
};
</script>

<style lang="less">
@import url("~@/assets/style/var.less");
html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
