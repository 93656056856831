/*
 * @Author: zhongxq
 * @Date: 2023-10-21 18:00:39
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-29 22:10:08
 * @FilePath: \beijing-peoples-art-museum\src\service\culture.js
 * @Description:
 *
 */
import service from "./index";

// 获取期刊列表
export const getJournalPage = (data) => {
  return service({
    url: "/open/portal/journal/page",
    method: "post",
    data,
  });
};
// 获取周边服务
export const getServicePage = (data) => {
  return service({
    url: "/open/portal/service/page",
    method: "post",
    data,
  });
};

// 获取文章列表
export const getArticlePage = (data) => {
  return service({
    url: "/open/portal/article/page",
    method: "post",
    data,
  });
};
// 获取文章详情
export const getArticleDetail = (id) => {
  return service({
    url: `/open/portal/article/getById/${id}`,
    method: "get",
  });
};
// 点击量+1
export const articleHit = (id) => {
  return service({
    url: `/open/portal/article/hit/${id}`,
    method: "get",
  });
};
// 获取推荐视频
export const getVideoPage = () => {
  return service({
    url: "/open/portal/video",
    method: "get",
  });
};
