export default [
  {
    path: "/renyiOverview",
    redirect: "/renyiOverview/introduction",
    component: () =>
      import(
        /* webpackChunkName: "renyiOverview" */ "@/views/mobile/renyiOverview/index.vue"
      ),
    meta: {
      title: "人艺概览",
    },
    children: [
      {
        path: "/renyiOverview/introduction",
        component: () =>
          import(
            /* webpackChunkName: "renyiOverview" */ "@/views/mobile/renyiOverview/introduction.vue"
          ),
        meta: {
          title: "剧院简介",
        },
      },
      {
        path: "/renyiOverview/shiyanjuchang",
        component: () =>
          import(
            /* webpackChunkName: "renyiOverview" */ "@/views/mobile/renyiOverview/shiyanjuchang.vue"
          ),
        meta: {
          title: "实验剧场",
        },
      },
      {
        path: "/renyiOverview/caoyu",
        component: () =>
          import(
            /* webpackChunkName: "renyiOverview" */ "@/views/mobile/renyiOverview/caoyu.vue"
          ),
        meta: {
          title: "曹禺剧场",
        },
      },
      {
        path: "/renyiOverview/xiaojuchang",
        component: () =>
          import(
            /* webpackChunkName: "renyiOverview" */ "@/views/mobile/renyiOverview/xiaojuchang.vue"
          ),
        meta: {
          title: "实验剧场",
        },
      },
      {
        path: "/renyiOverView/organization",
        component: () =>
          import(
            /* webpackChunkName: "renyiOverview" */ "@/views/mobile/renyiOverview/organization.vue"
          ),
        mate: {
          title: "组织机构",
        },
      },
      {
        path: "/renyiOverView/capitalTheatre",
        component: () =>
          import(
            /* webpackChunkName: "renyiOverview" */ "@/views/mobile/renyiOverview/capitalTheatre.vue"
          ),
        mate: {
          title: "首都剧场",
        },
      },
      {
        path: "/renyiOverView/theatreCenter",
        component: () =>
          import(
            /* webpackChunkName: "renyiOverview" */ "@/views/mobile/renyiOverview/theatreCenter.vue"
          ),
        mate: {
          title: "北京国际戏剧中心",
        },
      },
      {
        path: "/renyiOverView/juYinTheatre",
        component: () =>
          import(
            /* webpackChunkName: "renyiOverview" */ "@/views/mobile/renyiOverview/juYinTheatre.vue"
          ),
        mate: {
          title: "菊隐剧场",
        },
      },
      {
        path: "/renyiOverView/createCenter",
        component: () =>
          import(
            /* webpackChunkName: "renyiOverview" */ "@/views/mobile/renyiOverview/createCenter.vue"
          ),
        mate: {
          title: "北京人艺艺术创作中心",
        },
      },
    ],
  },
];
