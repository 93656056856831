import axios from "axios";

const apiBaseURL = process.env.NODE_ENV === "development" ? "/api" : "/api";
// const apiTimeout = 8000;
const apiTimeout = 30000;

// 创建一个 axios 实例
const service = axios.create({
  baseURL: apiBaseURL,
  timeout: apiTimeout, // 请求超时时间
  headers: {
    "X-Frame-Options": "DENY",
  },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => config,
  (error) => {
    // 发送失败
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const dataAxios = response.data;
    // 状态码需确认
    if (["200", "0", 0, 200].includes(dataAxios.code)) {
      return dataAxios;
    } else {
      return Promise.reject(new Error(dataAxios.message || "网络正在开小差"));
    }
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误";
          break;
        case 401:
          error.message = "未授权，请登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器内部错误";
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网关超时";
          break;
        case 505:
          error.message = "HTTP版本不受支持";
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
