<template>
  <el-image
    ref="imgRef"
    v-bind="$attrs"
    :src="formatSrc"
    :fit="fit"
    class="img"
    @click="click"
  >
    <div slot="error" class="image-slot">
      <img :class="clsName" :src="errorImg" />
    </div>
  </el-image>
</template>

<script>
export default {
  props: {
    src: {
      type: [String, Object],
      default: "",
    },
    fit: {
      type: String,
      default: "cover",
    },
    errorType: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      clsName: "big-w",
    };
  },
  computed: {
    formatSrc() {
      let src = this.src;
      // if (src && src.indexOf("assets/img") < 0 && src.indexOf("http") < 0) {
      //   src = BASE_FILE_URL + src;
      // }
      return src;
    },
    errorImg() {
      let obj = require("@/assets/img/error-img.jpg");
      if (this.errorType === "artist") {
        obj = require("@/assets/img/error-img-artist.jpg");
      }
      return obj;
    },
  },
  mounted() {
    if (!this.$refs.imgRef?.$el) {
      setTimeout(() => {
        this.setClsName();
      }, 500);
      return;
    }
    this.setClsName();
  },
  methods: {
    click() {
      this.$emit("click");
    },
    setClsName() {
      if (!this.$refs.imgRef?.$el) {
        return;
      }
      if (
        this.$refs.imgRef.$el.clientHeight > this.$refs.imgRef.$el.clientWidth
      ) {
        this.clsName = "big-h";
      } else if (
        this.$refs.imgRef.$el.clientHeight === this.$refs.imgRef.$el.clientWidth
      ) {
        this.clsName = "w-and-h";
      }
    },
  },
};
</script>

<style lang="less">
.img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-slot {
  width: 100%;
  height: 100%;
  background: #f3f3f3;
  position: relative;

  img {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover !important;
  }
  .big-h {
    width: 80%;
  }
  .big-w {
    height: 80%;
  }
  .w-and-h {
    width: 80%;
    height: 80%;
  }
}
</style>
