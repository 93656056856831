<template>
  <div class="breadcrumb">
    <div class="breadcrumb-title">当前位置：</div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="item in breadcrumbList" :key="item">{{
        item
      }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>


<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    breadcrumbList () {
      return ['首页', ...this.$store.state.breadcrumbList, ...this.list]
    }
  }
};
</script>

<style lang="less">
.breadcrumb {
  font-size: 0.7292vw;
  color: #909399;
  display: flex;
  &-title {
    color: #212122;
  }
  .el-breadcrumb {
    line-height: initial;
    .el-breadcrumb__item {
      font-size: 0.7292vw;
      .el-breadcrumb__separator {
        font-size: 0.7292vw;
        margin: 0 0.2083vw;
      }
      &:last-child {
        .el-breadcrumb__inner {
          font-size: 0.7292vw;
          font-weight: 500;
          color: #f9680d;
        }
      }
    }
  }
}
</style>