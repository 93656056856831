export default [
    {
        path: "/test",
        redirect: '/test/testa',
        meta: {
            title: '测试'
        },
        children: [
            {
                path: "/test/testa",
                meta: {
                    title: '测试a'
                }      
            },
            {
                path: "/test/testb",
                redirect: '/test/testb/ccc',
                meta: {
                    title: '测试b'
                },
                children: [
                    {
                        path: "/test/testb/ccc",
                        meta: {
                            title: '测试ccc'
                        }      
                    }
                ]
            },
        ]
      },
];
  