export default [
  {
    path: "/home",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/mobile/home/index.vue"),
    meta: {
      title: "北京人民艺术剧院",
    },
  },
];
