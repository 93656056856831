export default [
  {
    path: "/renyiTheatre",
    component: () =>
      import(
        /* webpackChunkName: "renyiTheatre" */ "@/views/pc/renyiTheatre/index.vue"
      ),
    redirect: "/renyiTheatre/introduction",
    meta: {
      title: "人艺剧场",
    },
    children: [
      {
        path: "/renyiTheatre/introduction",
        component: () =>
          import(
            /* webpackChunkName: "renyiTheatre" */ "@/views/pc/renyiTheatre/introduction.vue"
          ),
        mate: {
          title: "北京人艺演出中心",
        },
      },
      // {
      //     path: '/renyiTheatre/organization',
      //     component: () => import(/* webpackChunkName: "renyiTheatre" */ "@/views/pc/renyiOverview/organization.vue"),
      //     mate: {
      //         title: '组织机构'
      //     }
      // },
      {
        path: "/renyiTheatre/capitalTheatre",
        component: () =>
          import(
            /* webpackChunkName: "renyiTheatre" */ "@/views/pc/renyiTheatre/capitalTheatre.vue"
          ),
        mate: {
          title: "首都剧场",
        },
      },
      {
        path: "/renyiTheatre/theatreCenter",
        component: () =>
          import(
            /* webpackChunkName: "renyiTheatre" */ "@/views/pc/renyiTheatre/theatreCenter.vue"
          ),
        mate: {
          title: "北京国际戏剧中心",
        },
      },
      {
        path: "/renyiTheatre/juYinTheatre",
        component: () =>
          import(
            /* webpackChunkName: "renyiTheatre" */ "@/views/pc/renyiTheatre/juYinTheatre.vue"
          ),
        mate: {
          title: "菊隐剧场",
        },
      },
      {
        path: "/renyiTheatre/foundation",
        component: () =>
          import(
            /* webpackChunkName: "renyiTheatre" */ "@/views/pc/renyiTheatre/foundation.vue"
          ),
        mate: {
          title: "北京人艺发展基金会",
        },
      },
      {
        path: "/renyiTheatre/createCenter",
        component: () =>
          import(
            /* webpackChunkName: "renyiTheatre" */ "@/views/pc/renyiTheatre/createCenter.vue"
          ),
        mate: {
          title: "北京人艺戏剧书店",
        },
      },
    ],
  },
];
