import homeRoute from "./home";
import testRoute from "./test";
import PeopleEntertainersRoute from "./PeopleEntertainers";
import performanceRoute from "./performance";
import culture from "./culture";
import ArtsInformation from "./ArtsInformation";
import renyiOverviewRoute from "./renyiOverview";
import theatreMuseumRoute from "./theatreMuseum";
import survey from "./survey";
import search from "./search";
import renyiTheatre from "./renyiTheatre";

export default [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/pc/index.vue"),
    redirect: "/home",
    children: [
      // ...homeRoute,
      ...testRoute,
      ...PeopleEntertainersRoute,
      ...performanceRoute,
      ...ArtsInformation,
      ...culture,
      ...renyiOverviewRoute,
      ...theatreMuseumRoute,
      ...survey,
      ...search,
      ...renyiTheatre,
    ],
  },
  ...homeRoute,
];
