export default [
  {
    path: "/culture",
    redirect: "/culture/collection/index",
    component: () => import("@/views/mobile/culture/layout.vue"),
    meta: {
      title: "人艺文化",
    },
    children: [
      {
        path: "/culture/collection/index",
        component: () => import("@/views/mobile/culture/collection/index.vue"),
        meta: {
          title: "剧目典藏",
        },
      },
      {
        path: "/culture/collection/detail/:id",
        component: () => import("@/views/mobile/performance/detail.vue"),
        meta: {
          title: "剧目信息",
        },
      },
      {
        path: "/culture/video/index",
        component: () => import("@/views/mobile/culture/video/index.vue"),
        meta: {
          title: "人艺视界",
        },
      },
      {
        path: "/culture/video/detail/:id",
        component: () => import("@/views/mobile/culture/video/detail.vue"),
        meta: {
          title: "视频播放",
        },
      },
      {
        path: "/culture/periodical/index",
        component: () => import("@/views/mobile/culture/periodical/index.vue"),
        meta: {
          title: "阅读人艺",
        },
      },
      {
        path: "/culture/guide/index",
        component: () => import("@/views/mobile/culture/guide/index.vue"),
        meta: {
          title: "观剧指南",
        },
      },
    ],
  },
];
