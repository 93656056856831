export default [
  {
    path: "/theatreMuseum",
    component: () =>
      import(
        /* webpackChunkName: "theatreMuseum" */ "@/views/mobile/theatreMuseum/index.vue"
      ),
    meta: {
      title: "戏剧博物馆",
    },
    children: [],
  },
  {
    path: "/theatreMuseum/exhibitList",
    component: () =>
      import(
        /* webpackChunkName: "theatreMuseum" */ "@/views/mobile/theatreMuseum/exhibitList.vue"
      ),
    meta: {
      title: "陈列展品列表",
    },
  },
  {
    path: "/theatreMuseum/exhibitDetail",
    component: () =>
      import(
        /* webpackChunkName: "theatreMuseum" */ "@/views/mobile/theatreMuseum/exhibitDetail.vue"
      ),
    meta: {
      title: "陈列展品详情",
    },
  },
  {
    path: "/theatreMuseum/digitalList",
    component: () =>
      import(
        /* webpackChunkName: "theatreMuseum" */ "@/views/mobile/theatreMuseum/digitalList.vue"
      ),
    meta: {
      title: "数字展览列表",
    },
  },
  {
    path: "/theatreMuseum/digitalDetail",
    component: () =>
      import(
        /* webpackChunkName: "theatreMuseum" */ "@/views/mobile/theatreMuseum/digitalDetail.vue"
      ),
    meta: {
      title: "数字展览详情",
    },
  },
];
