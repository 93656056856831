export default [
    {
        path: "/PeopleEntertainers",
        component: () =>import(/* webpackChunkName: "about" */ "@/views/mobile/PeopleEntertainers/index.vue"),
        redirect: '/PeopleEntertainers/founder',
        children:[
            {
                path:'/PeopleEntertainers/founder',
                component:() =>import('@/views/mobile/PeopleEntertainers/founder.vue'),
                mate:{
                    title:'创始人'
                }
            },
            {
                path:'/PeopleEntertainers/screenwriter',
                component:() => import ('@/views/mobile/PeopleEntertainers/screenwriter.vue'),
                mate:{
                    title:'编剧'
                }
            },
            {
                path:'/PeopleEntertainers/director',
                component:() => import('@/views/mobile/PeopleEntertainers/director.vue'),
                mate:{
                    title:'导演'
                }
            },
            {
                path:'/PeopleEntertainers/actor',
                component:() => import('@/views/mobile/PeopleEntertainers/actor.vue'),
                mate:{
                    title:'演员'
                }
            },
            {
                path:'/PeopleEntertainers/devise',
                component:() => import('@/views/mobile/PeopleEntertainers/devise'),
                mate:{
                    title:'设计'
                }
            },
            {
                path:'/PeopleEntertainers/stage',
                component:() => import('@/views/mobile/PeopleEntertainers/stage'),
                mate:{
                    title:'舞美'
                }
            },
            {
                path:'/PeopleEntertainers/detail',
                component:() =>import ('@/views/mobile/PeopleEntertainers/detail'),
                mate:{
                    title:'演员资料'
                }
            }
        ]
    }
];