import Vue from "vue";
import Vuex from "vuex";
import { getSystemDict } from "@/service/system";
import { getTheme } from "@/service/home";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    breadcrumbList: [],
    dictMap: {
      // 形如key:[]
    },
    // 是否在移动端
    $isMobile:
      /Mobi|Android|iPhone/i.test(navigator.userAgent) ||
      window.innerWidth <= 750,
    systemTheme: '',
    systemThemeInfo: {},
  },
  mutations: {
    /**
     * 存放面包屑数据
     */
    setBreadcrumbList(state, data) {
      state.breadcrumbList = data;
    },
    SET_DICT_MAP(state, data) {
      console.log(data);
      state.dictMap = data;
    },
    SET_SINGEL_DICT_MAP(state, data) {
      console.log(data);
      state.dictMap[data.type] = data.list;
    },
    SET_SYSTEM_THEME(state, data) {
      state.systemTheme = data;
    },
    SET_SYSTEM_THEME_INFO(state, data) {
      state.systemThemeInfo = data;
    },
  },
  actions: {
    // 获取字典值，codes为字典数组
    async getDict({ commit, state }, codes) {
      let dictTypes = [];
      let dictMap = { ...state.dictMap };
      if (Array.isArray(codes)) {
        dictTypes = codes.filter(
          (code) => !(dictMap[code] && dictMap[code].length)
        );
      } else {
        // codes 为字典数组
        return;
      }

      let apis = [];
      dictTypes.forEach((type) => {
        dictMap[type] = [];
        const api = getSystemDict({ type });
        apis.push(api);
      });
      try {
        let res = await Promise.all(apis);
        res.forEach((item, index) => {
          dictMap[dictTypes[index]] = item?.data || [];
        });
        commit("SET_DICT_MAP", dictMap);
      } catch (e) {
        console.log(e);
      }
    },
    // 获取系统主题
    async getSystem({ commit, state }) {
      getTheme().then(res => {
        commit("SET_SYSTEM_THEME", res.data.code);
        commit("SET_SYSTEM_THEME_INFO", res.data);
      })
    }
  },
});
