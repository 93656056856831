import service from "./index";

// 获取门户信息
export const getHomePortal = (data) => {
  return service({
    url: "/open/portal/",
    method: "get",
    data,
  });
};

//获取主题
export const getTheme = (ata) => {
  return service({
    url: "/open/portal/system-theme/getTheme",
    method: "get",
  });
};
