import service from "./index";

// 获取字典
export const getSystemDict = (data) => {
  return service({
    url: "/open/portal/system/system-dict/list",
    method: "post",
    data,
  });
};

// 获取推荐
export const getSysHomeConfigList = (data) => {
  return service({
    url: "/open/portal/sys-home-config/list",
    method: "post",
    data,
  });
};

// 获取推荐分页
export const getSysHomeConfigPage = (data) => {
  return service({
    url: "/open/portal/sys-home-config/page",
    method: "post",
    data,
  });
};

//增加访问量
export const addVisits = (data) => {
  return service({
    url: "/open/record/",
    method: "post",
    data,
  });
};
