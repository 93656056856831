export default [
  {
    path: "/performance",
    redirect: "/performance/index",
    component: () => import("@/views/mobile/performance/layout.vue"),
    meta: {
      title: "人艺演出",
    },
    children: [
      {
        path: "/performance/index",
        component: () => import("@/views/mobile/performance/index.vue"),
        meta: {
          title: "正在演出",
        },
      },
      {
        path: "/performance/detail/:id",
        component: () => import("@/views/mobile/performance/detail.vue"),
        meta: {
          title: "剧目信息",
        },
      },
    ],
  },
];
