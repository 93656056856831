import service from "./index";

//人艺资讯
export const getByLevel = (data) => {
  return service({
    url: "/open/portal/article/page",
    method: "post",
    data,
  });
};

//人艺资讯详情
export const getPortalDetail = (id) => {
  return service({
    url: `/open/portal/article/getById/${id}`,
    method: "get",
  });
};

//点击量+1
export const portalHit = (id) => {
  return service({
    url: `/open/portal/article/hit/${id}`,
    method: "get",
  });
};
