export default [
  {
    path: "/ArtsInformation",
    component: () => import("@/views/mobile/ArtsInformation/index.vue"),
    redirect: "/ArtsInformation/dynamic",
    children: [
      {
        path: "/ArtsInformation/dynamic",
        component: () => import("@/views/mobile/ArtsInformation/dynamic.vue"),
        mate: {
          title: "剧院动态",
        },
      },
      {
        path: "/ArtsInformation/announcement",
        component: () =>
          import("@/views/mobile/ArtsInformation/announcement.vue"),
        mate: {
          title: "剧院公告",
        },
      },
      {
        path: "/ArtsInformation/recommend",
        component: () => import("@/views/mobile/ArtsInformation/recommend.vue"),
        mate: {
          title: "剧目推介",
        },
      },
      {
        path: "/ArtsInformation/activity",
        component: () => import("@/views/mobile/ArtsInformation/activity.vue"),
        mate: {
          title: "人艺活动",
        },
      },
      {
        path: "/ArtsInformation/detail",
        component: () => import("@/views/mobile/ArtsInformation/detail"),
        mate: {
          title: "详情页",
        },
      },
    ],
  },
];
