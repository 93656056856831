export default [
  {
    path: "/renyiOverView",
    component: () =>
      import(
        /* webpackChunkName: "renyiOverview" */ "@/views/pc/renyiOverview/index.vue"
      ),
    meta: {
      title: "人艺概览",
    },
  },
];
