/*
 * @Author: zhongxq
 * @Date: 2023-10-27 17:52:23
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-27 17:53:30
 * @FilePath: \beijing-peoples-art-museum\src\mixins\global.js
 * @Description:
 *
 */
export default {
  computed: {
    $isMobile() {
      return this.$store.state.$isMobile;
    },
  },
};
